import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitSalesForm } from '../../Services/FormSubmissionService';
import { FormImageSubmission } from '../../Models/FormImageSubmission';
import { SalesFormModel } from '../../Models/SalesFormModel';
import { ImageFilesAllowed } from '../../Constants/FileTypesAllowed';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

interface DialogProps {
  isSalesDialogOpen: boolean;
  closeSalesDialog: () => void;
  isExpanded?: boolean;
}

const ContactSalesDialog: React.FC<DialogProps> = ({
  isSalesDialogOpen,
  closeSalesDialog,
  isExpanded: isExpandedDeafult = false
}) => {
  // Define your state variables with their default values
  const defaultTypeOfBoat = '';
  const defaultBoatLength = '';
  const defaultBoatName = '';
  const defaultCustomerEmail = '';
  const defaultCustomerPhoneNumber = '';
  const defaultCustomerQuestionOrComment = '';
  const defaultImageFile = null;
  const defaultRecaptchaValue = null;
  const defaultIsSubmitting = false;

  const [typeOfBoat, setTypeOfBoat] = useState(defaultTypeOfBoat);
  const [boatLength, setBoatLength] = useState(defaultBoatLength);

  const [boatName, setBoatName] = useState(defaultBoatName);
  const [customerEmail, setCustomerEmail] = useState(defaultCustomerEmail);
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState(
    defaultCustomerPhoneNumber
  );
  const [customerQuestionOrComment, setCustomerQuestionOrComment] = useState(
    defaultCustomerQuestionOrComment
  );
  const [imageFile, setImageFile] = useState<File | null>(defaultImageFile);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(
    defaultRecaptchaValue
  );
  const [isSubmitting, setIsSubmitting] =
    useState<boolean>(defaultIsSubmitting);
  const [submittedSuccessfully, setSubmittedSuccessfully] =
    useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedDeafult);

  // Validation for email input
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmail);
  const containsLetters = /[a-zA-Z]/.test(customerPhoneNumber);

  const isSubmitDisabled =
    customerEmail.length === 0 || !isEmailValid || recaptchaValue == null;

  const handleClose = (): void => {
    if (!isSubmitting) {
      resetToDefaults();
      closeSalesDialog();
    }
  };

  // Function to reset all state variables to their default values
  const resetToDefaults = (): void => {
    setTypeOfBoat(defaultTypeOfBoat);
    setBoatLength(defaultBoatLength);
    setBoatName(defaultBoatName);
    setCustomerEmail(defaultCustomerEmail);
    setCustomerPhoneNumber(defaultCustomerPhoneNumber);
    setCustomerQuestionOrComment(defaultCustomerQuestionOrComment);
    setImageFile(defaultImageFile);
    setRecaptchaValue(defaultRecaptchaValue);
    setSubmittedSuccessfully(false);
    setIsExpanded(isExpandedDeafult);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];

    if (file != null) {
      const alerts: string[] = [];

      if (file.size > 10 * 1024 * 1024) {
        alerts.push('Image size should be less than 3MB.');
      }

      const fileExtension: string | undefined = file.name.split('.').pop();
      if (!ImageFilesAllowed.includes(fileExtension?.toLowerCase() ?? '')) {
        alerts.push('Only image files are allowed.');
      }

      if (alerts.length > 0) {
        alert(alerts.join('\n'));
        return;
      }

      setImageFile(file);
    }
  };

  const handleSubmit = (): void => {
    setIsSubmitting(true);
    if (recaptchaValue !== null) {
      SubmitSalesForm(
        new SalesFormModel(
          // typeOfBoat,
          boatLength,
          boatName,
          customerEmail,
          customerPhoneNumber,
          customerQuestionOrComment,
          '',
          imageFile,
          recaptchaValue
        )
      )
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          // if (!response.ok) {
          //   throw new Error('Form submission failed');
          // }
          console.log('Form submitted successfully');
          setSubmittedSuccessfully(true);
          setTimeout(() => {
            setIsSubmitting(false);
            handleClose();
          }, 1500);
          // showSuccessMessage();
        })
        .catch((error) => {
          console.error(error);
          setIsSubmitting(false);
          handleClose();
        });
    }
  };

  return (
    <Modal
      open={isSalesDialogOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: 280,
          maxWidth: 500,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
          maxHeight: '80vh', // Set a maximum height based on viewport height (80% of viewport height)
          overflowY: 'auto', // Enable vertical scrolling if the content overflows the max height
          boxSizing: 'border-box'
        }}
      >
        <h2
          id="modal-title"
          style={{ marginBlock: '0px', marginBottom: '10px' }}
        >
          Contact Sales
        </h2>
        {!isExpanded && (
          <p>
            To receive an emailed quote, please click and fill out boat details
            in quote form below.
          </p>
        )}
        <Collapsible
          trigger={
            <div>
              {!isExpanded && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '.5em',
                    paddingBottom: '.5em',
                    cursor: 'pointer' // Add cursor style to indicate interactivity
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsExpanded(!isExpandedDeafult);
                    }} // Pass onClick directly to the trigger element
                    sx={{
                      cursor: 'pointer',
                      transition:
                        'background-color 0.3s ease, transform 0.2s ease',
                      color: 'white',
                      backgroundColor: '#292a59'
                    }}
                    id="contact-sales-submission-button"
                  >
                    {isExpanded ? 'Collapse Form' : 'QUOTE FORM'}
                  </Button>
                </div>
              )}
            </div>
          }
          open={isExpanded}
        >
          {/* <FormControl fullWidth required sx={{ marginTop: '5px', marginBottom: '5px' }} >
            <InputLabel id="component-issue-dropdown-label">Type of Boat</InputLabel>
            <Select
              labelId="component-issue-dropdown-label"
              id="component-issue-dropdown"
              value={typeOfBoat}
              onChange={(e) => { setTypeOfBoat(e.target.value); }}
              label = 'Type of Boat'
              sx={{ textAlign: 'left' }}
            >
              <MenuItem value="Motor">Motor</MenuItem>
              <MenuItem value="Sail">Sail</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            label="Boat Length"
            value={boatLength}
            onChange={(e) => {
              setBoatLength(e.target.value);
            }}
            fullWidth
            required
            sx={{ marginTop: '5px', marginBottom: '5px' }}
          />
          <TextField
            label="Customer Name"
            value={boatName}
            onChange={(e) => {
              setBoatName(e.target.value);
            }}
            fullWidth
            required
            sx={{ marginTop: '5px', marginBottom: '5px' }}
          />
          <TextField
            label="Email"
            value={customerEmail}
            onChange={(e) => {
              setCustomerEmail(e.target.value);
            }}
            fullWidth
            required
            error={!isEmailValid && customerEmail.length > 0}
            helperText={
              !isEmailValid && customerEmail.length > 0
                ? 'Email format is Invalid.'
                : ''
            }
            sx={{ marginTop: '5px', marginBottom: '5px' }}
          />
          <TextField
            label="Phone Number"
            type="tel" // Set the input type to "tel"
            inputProps={{
              pattern: '[0-9]*' // Allow only numbers
            }}
            value={customerPhoneNumber}
            onChange={(e) => {
              setCustomerPhoneNumber(e.target.value);
            }}
            error={containsLetters && customerPhoneNumber.length > 0}
            helperText={
              containsLetters && customerPhoneNumber.length > 0
                ? 'Phone Number can not contain letters.'
                : ''
            }
            fullWidth
            sx={{ marginTop: '5px', marginBottom: '5px' }}
          />
          <TextField
            label="Question/Comment"
            value={customerQuestionOrComment}
            onChange={(e) => {
              setCustomerQuestionOrComment(e.target.value);
            }}
            multiline
            rows={4}
            fullWidth
            sx={{ marginTop: '5px', marginBottom: '5px' }}
            variant="standard"
            // required
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id="imageUpload"
          />
          <label htmlFor="imageUpload" style={{ margin: '10px' }}>
            <Button
              variant="contained"
              component="span"
              className="sales-upload-image"
              sx={{
                cursor: 'pointer',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
                color: 'white',
                backgroundColor: '#292a59'
              }}
            >
              Upload Image (Max 3MB)
            </Button>
          </label>
          <br />
          <p style={{ margin: '10px' }}>
            {imageFile != null
              ? `Selected: ${imageFile.name}`
              : 'No image selected'}
          </p>
          {imageFile !== null && (
            <div>
              <br />
              <img
                src={URL.createObjectURL(imageFile)} // Use URL.createObjectURL to display the image
                alt="Submitted Image"
                style={{ maxWidth: '100%', maxHeight: '200px', margin: '10px' }}
              />
            </div>
          )}
          <br />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            <ReCAPTCHA
              sitekey="6Ld3TU0oAAAAAG8WpRNlYn-9cFj9EfbuI2MbMijF"
              onChange={(value) => {
                setRecaptchaValue(value);
              }}
            />
          </div>
          {!submittedSuccessfully && isSalesDialogOpen && (
            <div style={{ paddingTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitDisabled || isSubmitting}
                sx={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease, transform 0.2s ease',
                  color: 'white',
                  backgroundColor: '#292a59'
                }}
                id="contact-sales-submission-button"
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                    id="contact-sales-submission-button"
                  />
                ) : (
                  <span id="contact-sales-submission-button">Submit</span>
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                sx={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease, transform 0.2s ease',
                  color: 'white',
                  backgroundColor: 'hsl(345, 96%, 42%)',
                  marginLeft: '15px'
                }}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          )}

          {submittedSuccessfully && isSalesDialogOpen && (
            <Alert>Successfully Submitted!</Alert>
          )}

          {!submittedSuccessfully && isSalesDialogOpen && (
            <small>* indicates required fields.</small>
          )}
        </Collapsible>
        {!isExpanded && (
          <div>
            <p>- or -</p>
            <p>
              If you are ready to make a purchase, please contact us at{' '}
              <a href={'tel:8555847878'} id="sales-dialog-contact-phone-number">
                (855) 584-7878.
              </a>
            </p>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ContactSalesDialog;
